<template>
  <div>
    <div :style="'background-image: url(' + loginBg + ');'" class="login-container">
      <div class="login-main-wrapper">
        <img src="@/assets/img/login/login-form-img.jpg" alt="" class="login-form-img">
        <div class="login-form-wrap">
          <div class="form-title">
            <img src="../assets/img/login/login-header.png" class="login-header-pic">
          </div>
          <el-form :model="loginForm" :rules="loginRules" class="login-form" label-positin="left" label-width="0px"
                   ref="loginRef">
            <el-form-item prop="phone">
              <el-input :autofocus="true" auto-complete="off" placeholder="手机号"
                        prefix-icon="el-icon-user" type="text" v-model="loginForm.phone"
                        maxlength="11"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input @keyup.native.13="loginFormSubmit('loginRef')" auto-complete="off" placeholder="密码"
                        prefix-icon="el-icon-lock" type="password" v-model="loginForm.password"></el-input>
            </el-form-item>
            <el-form-item prop="remember" style="font-size: 14px;margin: 0 0 15px 0;">
              <el-checkbox v-model="loginForm.remember" style="color: #18191A;">自动登录</el-checkbox>
              <!--              <a style="float: right;color: #0080FF ;" href="#">忘记密码</a>-->
            </el-form-item>
            <el-form-item class="submit-item">
              <el-button :loading="logining" @click.native.prevent="loginFormSubmit('loginRef')"
                         style="width: 100%;" type="primary">登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {login} from "@/api/auth"

export default {
  name: "Login",
  data() {
    return {
      loginBg: require('@/assets/img/login/login-background.jpg'),
      logining: false,
      loginForm: {
        phone: '',
        password: '',
        remember: false,
      },
      loginRules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号码'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ]
      },
    }
  },
  methods: {
    loginFormSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) return false
        that.logining = true
        login(that.loginForm).then(res => {
          that.logining = false
          that.$store.dispatch('auth/login', {authorization: res.token, user: res.user})
          if (res?.user?.firstMenu) {
            that.$router.push({path: res.user.firstMenu})
          } else {
            that.$router.push({path: '/admin/dashboard'})
          }
        }).catch(err => {
          that.logining = false
          console.log(err)
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  .login-main-wrapper {
    width: 884px;
    height: 500px;
    display: flex;
    overflow: hidden;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.14);

    .login-form-img {
      width: 500px;
      height: 500px;
    }

    .login-form-wrap {
      width: 384px;
      height: 500px;
      padding: 87px 32px;

      .form-title {
        margin-bottom: 32px;
        text-align: center;

        .login-header-pic {
          width: 180px;
          height: auto;
        }
      }

      .form-title.sub {
        justify-content: flex-end;
        color: #666;
        padding-bottom: 40px;
        font-size: 14px;
      }

      .login-form {
        /deep/ .el-input__prefix {
          color: #0D6CE4 !important;
          font-weight: bold !important;
        }
      }
    }
  }
}
</style>
